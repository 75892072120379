import { ReactNode, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import classes from "./AuthLayout.module.scss";
import Footer from "./ui/footer";
import Header from "./ui/header";
import Snackbar from "../../components/ui/snackbar";
import { useAppContext } from "../../context/AppContext";
import { getCookies } from "../../services/utils/functions/cookies";

const AuthLayout = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!getCookies("quincy-token")) {
      navigate("/login");
    }
  }, [navigate]);

  return isAuthenticated ? (
    <div className={classes.authLayout}>
      <Header />
      <Snackbar>
        <main className={classes.container}>{children}</main>
      </Snackbar>
      <Footer />
    </div>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export default AuthLayout;
