import { createTheme } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";

export const theme = createTheme({
  typography: {
    fontFamily: "Raleway",
  },
  palette: {
    primary: {
      main: "#FFFFFF",
      contrastText: "#595959",
    },
    secondary: {
      main: "#4B1E3F",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#D62347",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#D5EBDA",
      contrastText: "#000000",
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontWeight: 500,
          fontSize: "18px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input::placeholder": {
            fontStyle: "italic",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#000000",
            },
            "&:hover fieldset": {
              borderColor: "#4B1E3F",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4B1E3F",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          fontWeight: 700,
          fontSize: 18,
          borderRadius: "5px",
          border: "1px solid #1f50b0",
          padding: "14px 20px",
          margin: "28px 0 12px 0",
          textTransform: "initial",
        },
      },
    },
  },
});
