import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Polygon } from "../../../../../../assets/icons/polygon.svg";
import { useAppContext } from "../../../../../../context/AppContext";
import { getUsername } from "../../../../../../services/utils/functions";
import { removeCookies } from "../../../../../../services/utils/functions/cookies";
import {
  coPilotChromeURL,
  coPilotSafariURL,
  onboardingURL,
  settingPathMap,
  settings,
} from "../../../../constants";
import classes from "../../Header.module.scss";

const NavbarSettings = () => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const { user, logOut } = useAppContext();
  const userName = getUsername(user!);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettingClick = (setting: string) => {
    if (setting === "Log Out") {
      removeCookies("quincy-token");
      logOut();
    }
    switch (setting) {
      case "Onboarding":
        window.location.href = onboardingURL;
        break;
      case "CoPilot: Chrome":
        window.location.href = coPilotChromeURL;
        break;
      case "CoPilot: Safari":
        window.location.href = coPilotSafariURL;
        break;
      default:
        navigate(settingPathMap[setting]);
    }

    handleCloseUserMenu();
  };

  return (
    <div>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} disableRipple>
          <Avatar
            className={classes["navbar-avatar"]}
            sx={{
              bgcolor: "info.main",
              color: "info.contrastText",
              width: "68px",
              height: "68px",
              mr: "10px",
            }}
          >
            {userName}
          </Avatar>
          <Polygon
            className={anchorElUser ? classes.polygonActive : classes.polygon}
          />
        </IconButton>
      </Tooltip>
      {!!anchorElUser && (
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem key={setting} onClick={() => handleSettingClick(setting)}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default NavbarSettings;
