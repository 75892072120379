import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../../context/AppContext";
import {
  getUsername,
  truncateText,
} from "../../../../../services/utils/functions";
import { removeCookies } from "../../../../../services/utils/functions/cookies";
import {
  coPilotChromeURL,
  coPilotSafariURL,
  drawerPathMap,
  IDrawerPathMap,
  menuOptions,
  onboardingURL,
} from "../../../constants";

interface ICustomDrawer {
  isOpen: boolean;
  handleClose: () => void;
}

const CustomDrawer = memo(({ isOpen, handleClose }: ICustomDrawer) => {
  const navigate = useNavigate();

  const { user } = useAppContext();

  const userName = getUsername(user!);


  const handleClick = (option: string) => {
    handleClose();

    switch (option) {
      case "Onboarding":
        window.location.href = onboardingURL;
        break;
      case "CoPilot: Chrome":
        window.location.href = coPilotChromeURL;
        break;
        case "CoPilot: Safari":
          window.location.href = coPilotSafariURL;
          break;
      case "Log out":
        removeCookies("quincy-token");
        break;
      default:
        navigate(drawerPathMap[option as keyof IDrawerPathMap]);
    }
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div role="presentation">
        {menuOptions.map((menuOption, id) => {
          const subHeader = menuOption.name;
          const options = menuOption.data;
          return (
            <Accordion sx={{ padding: "10px" }} key={id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{subHeader}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {options.map((option, optionId) => {
                    return (
                      <ListItem
                        key={optionId}
                        button
                        onClick={() => handleClick(option)}
                      >
                        {option === "AccountName" ? (
                          <>
                            <Avatar
                              sx={{
                                bgcolor: "info.main",
                                color: "info.contrastText",
                                padding: "5px",
                                fontSize: "15px",
                                marginRight: "5px",
                              }}
                            >
                              {userName}
                            </Avatar>
                            <ListItemText
                              primary={truncateText(
                                `${user!.firstName} ${user!.lastName}`
                              )}
                            />
                          </>
                        ) : (
                          <ListItemText primary={option} />
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Drawer>
  );
});

export default CustomDrawer;
